import React from 'react';
import Layout from '../layout';
import { QUEUE_CLASS, QUEUE_CLASS_USAGE } from './snippet';

const Queue = () => {
  return (
    <Layout
      title="Queue"
      subTitle="A list on which we apply LIFO (Last In First Out) principle is known as Queue."
      img="asset/queue.webp"
      introduction={[
        'Hey, fellow coders. Welcome back to codienext to crack DSA in javascript. I am going to be showing you how to implement Queue in JavaScript. I will be showing you how to implement Queue using class based and function based queue.',
        'Before programming, I will give you an overview of what is Queue. Imagine you went to a coffee shop and you saw a line of people waiting in the Queue. The first person in line is served first. The second person in line is served next. The third person in line is served next. And so on. This is how Queue works.',
        "Let's start with class based queue. We'll be cracking queue using two methods in JavaScript. One is class based queue and other is function based queue.",
      ]}
      classBasedIntroduction="In class based stack, we need to create a class with push() and pop() methods. We need to create an object of the class in order to access the methods. Let's see how it looks like."
      snippet={[QUEUE_CLASS, QUEUE_CLASS_USAGE, QUEUE_CLASS, QUEUE_CLASS_USAGE]}
      functionBasedIntroduction="In function based stack, we utilize closure concept from javascript to implement methods like class based. The implementation of function based is also simple and similar to class based. Let's see how it looks like."
      classBasedConclusion={[
        'In the above snippet, you can see we have created a simple class which has 1 this variable and 3 methods to access the stack. If you face any difficulty in understanding, then you should understand classes and objects in javascript first.',
        "Let's see how to use the class based stack in JavaScript.",
      ]}
      functionBasedConclusion={[
        'In the above snippet, you can see we have created a simple function which is returning 3 functions in object. We can keep the stack instance by calling & storing it to a variable.',
        "Let's see how to use function based stack in javascript.",
      ]}
      conclusion={[
        "It's done! This article explains in two ways of implementing a Queue in JavaScript. The benefits of both approaches matter the most, whether you are more comfortable with classes or functions. The last thing you have to do is attempt writing logic for queue by your own and try solving some algo's on Queue.",
        'In case you have queries about this article, then feel free to reach out to me on my LinkedIn profile.',
      ]}
    />
  );
};

export default Queue;
