import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import DSAList from 'components/DSAList';

export default function HomePage() {
  return (
    <Stack spacing={8} alignItems="center">
      <Text as="h1" fontSize="3xl" fontWeight="bold" textAlign="center" my={2}>
        Let's make DSA easy with Codienext
      </Text>
      <DSAList />
    </Stack>
  );
}
