import React from 'react';

import Layout from '../layout';
import {
  STACK_CLASS,
  STACK_CLASS_USAGE,
  STACK_FUNCTION,
  STACK_FUNCTION_USAGE,
} from './snippet';

const Stack = () => {

  return (
    <Layout
      title="Stack"
      subTitle="An array on which we apply LIFO (Last In First Out) principle is known as Stack."
      img="asset/stack.gif"
      introduction={[
        'As I already mentioned earlier, Stack is nothing but just an array on which we apply LIFO principle. Now as you can see in the gif, we have used push() and pop() operations to add and remove elements from the Stack. In Stack, we can only add a new element in the top of the Stack and we can only remove an element from the top of the Stack which defines the Last In First Out principle.',
        "Now let's see an example of Stack in JavaScript. We can use other languages as well like C, C++, Java, Python etc. and they are much more faster in compilation as well but we are focused on JavaScript only.",
        "We'll be cracking stack using two methods in JavaScript. One is class based stack and other is function based stack.",
      ]}
      classBasedIntroduction="In class based stack, we need to create a class with push() and pop() methods. We need to create an object of the class in order to access the methods. Let's see how it looks like."
      snippet={[
        STACK_CLASS,
        STACK_CLASS_USAGE,
        STACK_FUNCTION,
        STACK_FUNCTION_USAGE,
      ]}
      functionBasedIntroduction="In function based stack, we utilize closure concept from javascript to implement methods like class based. The implementation of function based is also simple and similar to class based. Let's see how it looks like."
      classBasedConclusion={[
        'In the above snippet, you can see we have created a simple class which has 1 this variable and 3 methods to access the stack. If you face any difficulty in understanding, then you should understand classes and objects in javascript first.',
        "Let's see how to use the class based stack in JavaScript.",
      ]}
      functionBasedConclusion={[
        'In the above snippet, you can see we have created a simple function which is returning 3 functions in object. We can keep the stack instance by calling & storing it to a variable.',
        "Let's see how to use function based stack in javascript.",
      ]}
      conclusion="I hope this article helped you to understand Stack in JavaScript. If
              you still face some issue or want to ask anything, then feel free to
              reach out to me on my LinkedIn profile."
    />
  );

  // return (
  //   <ChakraStack spacing={8} alignItems="center">
  //     <Box mx={8}>
  //       <Text as="h1" fontSize="4xl" fontWeight="bold" textAlign="center">
  //         Stack
  //       </Text>
  //       <Text fontSize="l" fontWeight="bold" textAlign="center" mx={2}>
  //         An array on which we apply LIFO (Last In First Out) principle is known
  //         as Stack.
  //       </Text>
  //     </Box>
  //     <Box mx={8} mb={6} maxW={'6xl'}>
  //       <Grid templateColumns={{ base: '1fr', md: '1fr 2fr' }} gap={10}>
  //         <GridItem>
  //           <Image
  //             src="asset/stack.gif"
  //             h="300px"
  //             w="100%"
  //             objectFit="contain"
  //           />
  //         </GridItem>
  //         <GridItem>
  //           <Text mb={8}>
  //             As I already mentioned earlier, Stack is nothing but just an array
  //             on which we apply LIFO principle. Now as you can see in the gif,
  //             we have used push() and pop() operations to add and remove
  //             elements from the Stack. In Stack, we can only add a new element
  //             in the top of the Stack and we can only remove an element from the
  //             top of the Stack which defines the Last In First Out principle.
  //           </Text>
  //           <Text mb={8}>
  //             Now let's see an example of Stack in JavaScript. We can use other
  //             languages as well like C, C++, Java, Python etc. and they are much
  //             more faster in compilation as well but we are focused on
  //             JavaScript only.
  //           </Text>

  //           <Text mb={8}>
  //             We'll be cracking stack using two methods in JavaScript. One is
  //             class based stack and other is function based stack.
  //           </Text>
  //         </GridItem>
  //       </Grid>
  //       <Card my={8}>
  //         <CardBody>
  //           <Text textAlign={'center'}>
  //             I recommend you all to download{' '}
  //             <Link color={'#f2da00'} href="https://runjs.app/">
  //               RunJs
  //             </Link>{' '}
  //             desktop application. It's a free tool to try & run js code.
  //           </Text>
  //         </CardBody>
  //       </Card>
  //       <Grid templateColumns={{ base: '1fr', md: '1fr auto 1fr' }} gap={10}>
  //         <GridItem>
  //           <Text fontSize={'xl'} fontWeight="bold" my={8}>
  //             Class based STACK
  //           </Text>
  //           <Text my={8}>
  //             In class based stack, we need to create a class with push() and
  //             pop() methods. We need to create an object of the class in order
  //             to access the methods. Let's see how it looks like.
  //           </Text>
  //           <CodeSnippet snippet={STACK_CLASS} />
  //           <Text my={8}>
  //             In the above snippet, you can see we have created a simple class
  //             which has 1 this variable and 3 methods to access the stack. If
  //             you face any difficulty in understanding, then you should
  //             understand classes and objects in javascript first.
  //             <br />
  //             <br />
  //             Let's see how to use the class based stack in JavaScript.
  //           </Text>
  //           <CodeSnippet snippet={STACK_CLASS_USAGE} />
  //         </GridItem>
  //         {showDivider && (
  //           <GridItem>
  //             <Divider orientation="vertical" />
  //           </GridItem>
  //         )}
  //         <GridItem>
  //           <Text fontSize={'xl'} fontWeight="bold" my={8}>
  //             Function based STACK
  //           </Text>
  //           <Text my={8}>
  //             In function based stack, we utilize closure concept from
  //             javascript to implement methods like class based. The
  //             implementation of function based is also simple and similar to
  //             class based. Let's see how it looks like.
  //           </Text>
  //           <CodeSnippet snippet={STACK_FUNCTION} />
  //           <Text my={8}>
  //             In the above snippet, you can see we have created a simple
  //             function which is returning 3 functions in object. We can keep the
  //             stack instance by calling & storing it to a variable. <br />
  //             <br />
  //             Let's see how to use function based stack in javascript.
  //           </Text>
  //           <CodeSnippet snippet={STACK_FUNCTION_USAGE} />
  //         </GridItem>
  //       </Grid>
  //       <Text my={8}>
  //         I hope this article helped you to understand Stack in JavaScript. If
  //         you still face some issue or want to ask anything, then feel free to
  //         reach out to me on my LinkedIn profile.
  //       </Text>
  //     </Box>
  //   </ChakraStack>
  // );
};

export default Stack;
