import React from 'react';
import {
  Text,
  Stack as ChakraStack,
  Box,
  Image,
  Grid,
  GridItem,
  Card,
  CardBody,
  Link,
  Divider,
  useBreakpointValue,
} from '@chakra-ui/react';
import CodeSnippet from 'components/CodeSnippet';

export default function Layout({
  title,
  subTitle,
  img = 'asset/stack.gif',
  introduction,
  classBasedIntroduction,
  classBasedConclusion,
  snippet,
  functionBasedIntroduction,
  functionBasedConclusion,
  conclusion,
}) {
  const showDivider = useBreakpointValue({ base: false, md: true });

  return (
    <ChakraStack spacing={8} alignItems="center">
      <Box mx={8}>
        <Text as="h1" fontSize="4xl" fontWeight="bold" textAlign="center">
          {title}
        </Text>
        <Text fontSize="l" fontWeight="bold" textAlign="center" mx={2}>
          {subTitle}
        </Text>
      </Box>
      <Box mx={8} mb={6} maxW={'6xl'}>
        <Grid templateColumns={{ base: '1fr', md: '1fr 2fr' }} gap={10}>
          {img && (
            <GridItem>
              <Image src={img} h="100%" w="100%" objectFit="contain" mt={2} />
            </GridItem>
          )}
          <GridItem>
            <Text as="h2" fontSize="4xl" fontWeight="bold">
              Introduction to {title}
            </Text>
            {renderText(introduction)}
          </GridItem>
        </Grid>
        <Card my={8}>
          <CardBody>
            <Text textAlign={'center'}>
              I recommend you all to download{' '}
              <Link color={'#f2da00'} href="https://runjs.app/">
                RunJs
              </Link>{' '}
              desktop application. It's a free tool to try & run js code.
            </Text>
          </CardBody>
        </Card>
        <Grid templateColumns={{ base: '1fr', md: '1fr auto 1fr' }} gap={10}>
          <GridItem>
            <Text fontSize={'xl'} fontWeight="bold" my={2}>
              Class based {title}
            </Text>
            {renderText(classBasedIntroduction)}
            <CodeSnippet snippet={snippet[0]} />
            {renderText(classBasedConclusion)}
            <CodeSnippet snippet={snippet[1]} />
          </GridItem>
          {showDivider && (
            <GridItem>
              <Divider orientation="vertical" />
            </GridItem>
          )}
          <GridItem>
            <Text fontSize={'xl'} fontWeight="bold" my={2}>
              Function based {title}
            </Text>
            {renderText(functionBasedIntroduction)}
            <CodeSnippet snippet={snippet[2]} />
            {renderText(functionBasedConclusion)}
            <CodeSnippet snippet={snippet[3]} />
          </GridItem>
        </Grid>
        {renderText(conclusion)} Happy Coding ✌️
      </Box>
    </ChakraStack>
  );
}

function renderText(text) {
  if (Array.isArray(text)) {
    return text.map(t => <Text my={4}>{t}</Text>);
  }
  return <Text my={4}>{text}</Text>;
}
