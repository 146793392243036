import React from 'react';
// import { ColorModeSwitcher } from 'components/Header/ColorModeSwitcher';
import { Flex, Image } from '@chakra-ui/react';
// import SocialIcons from 'components/Header/SocialIcons';

const Header = () => {
  return (
    <Flex alignItems={'center'} justifyContent={'center'} p={4}>
      {/* <SocialIcons /> */}
      <Image
        src="/asset/logo.png"
        alt="CODIENEXT Logo"
        cursor="pointer"
        onClick={() => (window.location.href = '/')}
        width={'180px'}
        objectFit="contain"
      />
      {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
    </Flex>
  );
};
export default Header;
