import React from 'react';
import { Flex } from '@chakra-ui/react';
import { FaFacebookSquare, FaGithubSquare, FaLinkedin } from 'react-icons/fa';

const SocialIcons = () => {
    return (
        <Flex w={"100px"} justifyContent={"space-between"} mx={2}>
            <FaFacebookSquare size={24} />
            <FaLinkedin size={24} />
            <FaGithubSquare size={24} />
        </Flex>
    );
};
export default SocialIcons;