const STACK_CLASS = `class Stack {
  constructor() {
    this.list = [];
  }

  push(item) {
    console.log("Pushed: " + item);
    this.list.push(item);
  }

  pop() {
    if (this.list.length == 0) {
      return "Stack is empty";
    }
    console.log("Popped");
    this.list.pop();
  }

  printStack() {
    if (this.list.length) console.log("Stack contains " + this.list);
    else console.log("Stack is empty");
  }
}`;

const STACK_CLASS_USAGE = `const stack = new Stack();
// created a new object for class stack. This will create an empty stack.

// call the push operation with some value in it.
stack.push(1);
stack.push(2);
stack.push(3);

// call the printStack operation
stack.printStack();

// call the pop operation
stack.pop(); // 3
stack.pop(); // 2
stack.pop(); // 1
stack.pop(); // Stack is empty`;

const STACK_FUNCTION = `function Stack() {
  const stack = [];

  return {
    push: (item) => {
      console.log("Pushed: " + item);
      stack.push(item);
    },
    pop: () => {
      if (stack.length == 0) {
        return "Stack is empty";
      }
      console.log("Popped");
      stack.pop();
    },
    printStack: () => {
      console.log("Stack contains " + stack);
    },
  };
}`;

const STACK_FUNCTION_USAGE = `const stack = Stack();
// Called stack function and stored it result in a variable. It similar to the instance we create for a Class.

// call the push operation with some value in it.
stack.push(1);
stack.push(2);
stack.push(3);

// call the printStack operation
stack.printStack();

// call the pop operation
stack.pop(); // 3
stack.pop(); // 2
stack.pop(); // 1
stack.pop(); // Stack is empty

// Method calling is same for both Class & function based method.`;

export { STACK_CLASS, STACK_CLASS_USAGE, STACK_FUNCTION, STACK_FUNCTION_USAGE };
