'use client';

import { useState } from 'react';
import {
  Stack,
  FormControl,
  Input,
  Button,
  useColorModeValue,
  Heading,
  Text,
  Container,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

export default function Newsletter() {
  const [email, setEmail] = useState('');
  const [state, setState] = useState('initial');
  const [error, setError] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);
    setState('submitting');

    try {
      const response = await fetch(
        'https://us-central1-tinder-2d29d.cloudfunctions.net/saveEmail',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        }
      );

      const result = await response.json();

      if (response.status === 200 && result.message === 'Success') {
        setState('success');
      } else if (
        response.status === 400 &&
        result.message === 'Email already exists'
      ) {
        setError("Email already exists");
        setState('initial');
      } else {
        setError('Oh no an error occured! 😢 Please try again later.');
        setState('initial');
      }
    } catch (error) {
      console.error('Error submitting email:', error);
      setError('Oh no an error occured! 😢 Please try again later.');
      setState('initial');
    }
  }

  return (
    <Stack>
      <Divider />
      <Flex align={'center'} justify={'center'} bg={'transparent'}>
        <Container
          maxW={'lg'}
          // bg={useColorModeValue('white', 'whiteAlpha.100')}
          p={6}
        >
          <Heading
            as={'h2'}
            fontSize={{ base: 'xl', sm: '2xl' }}
            textAlign={'center'}
            mb={5}
          >
            Subscribe to our Newsletter
          </Heading>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            as={'form'}
            spacing={'12px'}
            onSubmit={handleSubmit}
          >
            <FormControl>
              <Input
                variant={'solid'}
                borderWidth={1}
                color={'gray.200'}
                _placeholder={{
                  color: 'gray.400',
                }}
                borderColor={useColorModeValue('gray.300', 'gray.700')}
                id={'email'}
                type={'email'}
                required
                placeholder={'Your Email'}
                aria-label={'Your Email'}
                value={email}
                disabled={state !== 'initial'}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl w={{ base: '100%', md: '40%' }}>
              <Button
                colorScheme={state === 'success' ? 'green' : 'blue'}
                color={'white'}
                isLoading={state === 'submitting'}
                w="100%"
                type={state === 'success' ? 'button' : 'submit'}
                sx={{
                  bg: state === 'success' ? 'green.500' : 'blue.700',
                  _hover: {
                    bg: state === 'success' ? 'green.600' : 'blue.900',
                  },
                  _active: {
                    bg: state === 'success' ? 'green.700' : 'blue.800',
                  },
                  _focus: {
                    boxShadow: 'none',
                  },
                }}
              >
                {state === 'success' ? (
                  <FaCheckCircle color="white" />
                ) : (
                  'Submit'
                )}
              </Button>
            </FormControl>
          </Stack>
          <Text
            mt={2}
            textAlign={'center'}
            color={error ? 'red.500' : 'gray.500'}
          >
            {error ?? "You won't receive any spam! ✌️"}
          </Text>
        </Container>
      </Flex>
    </Stack>
  );
}
