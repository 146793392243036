import React from 'react';
import { Box } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';

import Header from 'components/Header/Header';
import { StarsBackground } from 'components/ShootingStarBg/StarBackground';
import { ShootingStars } from 'components/ShootingStarBg/ShootingStar';

import Home from 'pages/Home/Home';
import Stack from 'pages/DSA/Stack';

import 'App.css';
import Footer from 'components/Footer';
import Queue from 'pages/DSA/Queue';

function App() {
  return (
    <Box height="100vh" width="100vw" overflow="hidden">
      <ShootingStars />
      <StarsBackground />
      <Box
        height="100%"
        width="100%"
        overflowY="auto"
        // maxW="1000px"
        // m="auto"
      >
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stack" element={<Stack />} />
          <Route path="/queue" element={<Queue />} />
        </Routes>
        <Footer />
      </Box>
    </Box>
  );
}

export default App;
