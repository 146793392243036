import React from 'react';
import {
  Image,
  Card,
  CardBody,
  Heading,
  Flex,
  useColorModeValue,
  Badge,
} from '@chakra-ui/react';

const DSACard = ({ img, title, path }) => {
  const borderColor = useColorModeValue(
    'rgba(0, 0, 0, 0.2)',
    'rgba(255, 255, 255, 0.2)'
  );
  const textColor = useColorModeValue('blue.700', 'white');
  const bg = useColorModeValue('white', 'rgba(0, 0, 0, 0)');
  return (
    <Card
      height={'300px'}
      bg={bg}
      border={`1px solid ${borderColor}`}
      color="white"
      overflow={'hidden'}
      cursor={'pointer'}
      transition="transform 0.3s ease"
      _hover={{
        bg: 'blue.700',
        transform: 'scale(1.05)',
        borderColor: 'blue.700',
      }}
      onClick={path !== '/' ? () => (window.location.href = path) : null}
    >
      <CardBody p={0} textAlign={'center'}>
        {path === '/' && (
          <Badge
            variant="outline"
            colorScheme="red"
            pos={'absolute'}
            top={2}
            right={2}
            sx={{ color: 'red.500' }}
          >
            Coming Soon
          </Badge>
        )}
        <Image
          src={img}
          alt={`Image: ${title}`}
          h={'240px'}
          w={'100%'}
          objectFit={'cover'}
        />
        <Flex h={'60px'} justifyContent={'center'} alignItems={'center'}>
          <Heading color={textColor} size="md">
            {title}
          </Heading>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default DSACard;
