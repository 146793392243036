import { SimpleGrid } from '@chakra-ui/react';
import DSACard from 'components/DSACard';
import React from 'react';

const DSA = [
  {
    image: 'https://scaler.com/topics/images/stack-in-c.webp',
    title: 'Stack',
    path: '/stack',
  },
  {
    image: 'https://scaler.com/topics/images/queue-in-c.webp',
    title: 'Queue',
    path: '/queue',
  },
  {
    image: 'https://i.imgur.com/uDxzbB7.png',
    title: 'Linked List',
    path: '/linked',
  },
  {
    image: 'https://i.imgur.com/MfKZPLN.png',
    title: 'Graph',
    path: '/',
  },
  {
    image: 'https://www.scaler.com/topics/media/full-binary-tree.webp',
    title: 'Tree',
    path: '/',
  },
];

const DSAList = () => {
  return (
    <SimpleGrid
      columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} // Responsive column count
      spacing={4}
      mx={6}
      my={6}
      maxW={'6xl'}
    >
      {DSA.map(({ image, title, path }, index) => (
        <DSACard key={index} img={image} title={title} path={path} />
      ))}
    </SimpleGrid>
  );
};

export default DSAList;
