import { Button, Box, useColorModeValue } from '@chakra-ui/react';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/monokai.css';

import { FaCopy } from 'react-icons/fa';
import { useEffect, useRef, useState } from 'react';

hljs.registerLanguage('javascript', javascript);

const CodeSnippet = ({ snippet }) => {
  const codeRef = useRef(null);
  const [copy, setCopy] = useState(false);

  const codeBg = useColorModeValue('gray.900', 'transparent');

  useEffect(() => {
    hljs.highlightBlock(codeRef.current);
  }, [codeBg]);

  const handleCopy = () => {
    setCopy(true);
    const code = codeRef.current.innerText;
    navigator.clipboard.writeText(code);

    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  return (
    <Box w="100%" position="relative" display="inline-block">
      <Button
        leftIcon={copy ? null : <FaCopy />}
        colorScheme="teal"
        variant="outline"
        size="sm"
        onClick={handleCopy}
        position="absolute"
        top="10px"
        right="10px"
        zIndex="1"
        disabled={copy}
      >
        {copy ? 'Copied!' : 'Copy'}
      </Button>
      <Box
        as="pre"
        w="100%"
        m="0"
        position="relative"
        zIndex="0"
        overflow="auto"
      >
        <Box
          as="code"
          className="javascript"
          bg={codeBg}
          border="1px solid rgba(255, 255, 255, 0.2)"
          borderRadius="10px"
          p="20px"
          ref={codeRef}
          whiteSpace="pre-wrap"
          w="100%"
        >
          {snippet}
        </Box>
      </Box>
    </Box>
  );
};

export default CodeSnippet;
