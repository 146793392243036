const QUEUE_CLASS = `class Queue {
  constructor() {
    this.list = {};
    this.front = 0;
    this.back = 0;
  }

  isEmpty() {
    return this.front == this.back;
  }

  enqueue(item) {
    this.list[this.back] = item;
    this.back++;
  }

  dequeue() {
    if (this.isEmpty()) {
      return "Queue is empty!";
    }
    delete this.list[this.front];
    this.front++;
  }

  print() {
    return {
      list: this.list,
      front: this.front,
      back: this.back,
    };
  }
}`;

const QUEUE_CLASS_USAGE = `const queue = new Queue();
// created a new object for class queue. This will create an empty queue.

// call the enqueue operation with some value in it.
queue.enqueue(1);
queue.enqueue(2);
queue.enqueue(3);

// call the printQueue operation
queue.printQueue(); // 1,2,3

// call the dequeue operation
queue.dequeue(); // 1
queue.dequeue(); // 2

queue.printQueue(); // 3

queue.dequeue(); // 3
queue.dequeue(); // Queue is empty`;

const QUEUE_FUNCTION = `function Queue() {
  let list = {};
  let front = 0;
  let back = 0;

  const queue = {
    isEmpty: () => front == back,

    enqueue: (item) => {
      list[back] = item;
      back++;
    },

    dequeue: () => {
      if (queue.isEmpty()) {
        return "Queue is empty";
      } else {
        delete list[front];
        front++;
      }
    },

    print: () => {
      return {
        list: list,
        front: front,
        back: back,
      };
    },
  };

  return queue;
}`;

const QUEUE_FUNCTION_USAGE = `const queue = Queue();
// Create a new instance of Queue.

// Call the enqueue operation with some value in it.
queue.enqueue(1);
queue.enqueue(2);
queue.enqueue(3);

// Call the printQueue operation
queue.printQueue(); // 1,2,3

// Call the dequeue operation
queue.dequeue(); // 1
queue.dequeue(); // 2

queue.printQueue(); // 3

queue.dequeue(); // 3
queue.dequeue(); // Queue is empty`;

export { QUEUE_CLASS, QUEUE_CLASS_USAGE, QUEUE_FUNCTION, QUEUE_FUNCTION_USAGE };
