'use client';

import { Box, Container, Image, Stack, Text } from '@chakra-ui/react';
import SocialIcons from 'components/Header/SocialIcons';
import Newsletter from 'components/Newsletter';

export default function Footer() {
  return (
    <Box>
      <Newsletter />
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Image src="/asset/logo.png" alt="Codienext Logo" width="140px" />
        <Text>© 2024 Codienext. All rights reserved</Text>
        <SocialIcons />
      </Container>
    </Box>
  );
}
